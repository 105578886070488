.HomeContainer{

    background-image: url('../img/fondo.jpg');
    background-size: cover;
    margin: 0;
    padding: 0;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;








    .descriptionBrand{
      min-width: 70vw;
      max-width: 70vw;
      text-align: center;
      color: #e2e2e2;
      z-index: 3000;
      margin-top: 70vh;
      
    }
}





.Fondo{
    z-index: 1;
    position: absolute;
}
.lds-default {
    position: absolute;
    z-index: 5;
    display: inline-block;
    position: relative;
    width: 10vw;
    height: 10vw;
    display: flex;
  }
  .lds-default div {
    position: absolute;
    width: 0.7vw;
    height: 0.7vw;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 6.7vw;
    left: 4.7vw;
  }
  .lds-default div:nth-child(2) {
    animation-delay: +0.1s;
    top: 6.2vw;
    left: 3.4vw;
  }
  .lds-default div:nth-child(3) {
    animation-delay: +0.2s;
    top: 5vw;
    left: 2.7vw;
  }
  .lds-default div:nth-child(4) {
    animation-delay: +0.3s;
    top: 3.5vw;
    left: 3.2vw;
  }
  .lds-default div:nth-child(5) {
    animation-delay: +0.4s;
    top: 2.7vw;
    left: 4.7vw;
  }
  .lds-default div:nth-child(6) {
    animation-delay: +0.5s;
    top: 3.5vw;
    left: 6.3vw;
  }
  .lds-default div:nth-child(7) {
    animation-delay: +0.6s;
    top: 5vw;
    left: 6.7vw;
  }
  .lds-default div:nth-child(8) {
    animation-delay: +0.7s;
    top: 6.1vw;
    left: 6.2vw;
  }
  .lds-default div:nth-child(9) {
    
    top: 3vw;
    left: 3vw;
    width: 4vw;
    height: 4vw;

    background: transparent;
    border-radius: 50%;
    animation: none;
  }
  .lds-default div:nth-child(10) {
    
  } 
 
  /*
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  */
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
  }
  
.construccion{
  z-index: 2;
  background-color: transparent;
  position: absolute;
  top: 8vw;
  left: 0vw;
  animation: none;
  width: 100%;
  min-width: 50vw;
  font-size: 1.8rem;
  display: flex;
  justify-content:center;
  align-items: center;
  color: #fff;
  top: 0vh;
  min-height: 60vh;
  z-index: 20;
  

  // Nuevo codigo para  video 
  position: absolute;
  
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  background-color: #000;
  video{
    z-index: 2;
    display: flex;
    position: absolute;
    width: 100vw;
    min-width: 50vw;
    min-height: 50vh;
    z-index: 2;
    position: absolute;
  }
  
}

.logo{
  position: absolute;
  z-index: 2;
  top: 10vh;
  left: 8vw;
}


.footer{
  
  position: absolute;
  z-index: 20;
  border-top: 1px solid #fff;
  z-index: 20;
  min-height: 5vh;
  left: 0;
  top: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0, 0.3);
  width: 100vw;
    a{
      color: #fff;
      font-size: 1rem;
      text-decoration: none;
    }
}
.LogosIconos{
  flex-grow: 3;
  z-index: 20;

  display: flex;
  justify-content: flex-end;
  padding-right: 10vw;
  img{
    margin-left: 2vw;
  }
}

.LogoFooter{
  z-index: 20;

  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    max-width: 5vw;
  }

}
.directionMovil{
display: none;
}
.direction{
  z-index: 20;

  width: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
  a{
    
  }
}



@media (max-width: 700px){


  .HomeContainer{
    background-image: url('../img/Back-bloque-3.svg');
    background-size: cover;
    background-size: 200%;
    

    min-height: 90vh;
    max-height: 90vh;




    .descriptionBrand{
      min-width: 70vw;
      max-width: 70vw;
      text-align: center;
      color: #e2e2e2;
      z-index: 3000;
      margin: 0px;  
    }

  }

.Fondo{
  max-height: 90vh;
}
.lds-default {
    min-width: 40vw;
    min-height: 40vw;
    top: -5vh;
  }
  .lds-default {
    position: absolute;
    z-index: 5;
    display: inline-block;
    position: relative;
    width: 10vw;
    height: 10vw;
    display: flex;
  }
  .lds-default div {
    position: absolute;
    width: 3vw;
    height: 3vw;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 27.5vw;
    left: 17.8vw;
  }
  .lds-default div:nth-child(2) {
    animation-delay: +0.1s;
    top: 25vw;
    left: 10.5vw;
  }
  .lds-default div:nth-child(3) {
    animation-delay: +0.2s;
    top: 18vw;
    left: 8vw;
  }
  .lds-default div:nth-child(4) {
    animation-delay: +0.3s;
    top: 10.5vw;
    left: 10.54vw;
  }
  .lds-default div:nth-child(5) {
    animation-delay: +0.4s;
    top: 8vw;
    left: 17.8vw;
  }
  .lds-default div:nth-child(6) {
    animation-delay: +0.5s;
    top: 10.5vw;
    left: 24vw;
  }
  .lds-default div:nth-child(7) {
    animation-delay: +0.6s;
    top: 18vw;
    left: 28vw;
  }
  .lds-default div:nth-child(8) {
    animation-delay: +0.7s;
    top: 25vw;
    left: 25vw;
  }
  .lds-default div:nth-child(9) {
    top: 10vw;
    left: 10vw;
    width: 20vw;
    height: 20vw;

    //background: transparent;
   // border-radius: 50%;
    //animation: none;
  }
  .lds-default div:nth-child(10) {
   
  } 


.construccion{
  top: 70vh;
  font-size: 1.5rem;
}

.logo{
min-width: 100vw;
margin-left: -10vw;
padding: 0;
display: flex;
justify-content: center;
align-items: center;
img{
  width: 80%;
}
}


.footer{
    min-height: 15vh;
    top: 75vh;
    display: flex;
    flex-direction: column;
    background: rgba(0,0,0, 0.3);
    a{
    }
}
.LogosIconos{
  margin: 0;
  padding: 0;
  min-height: 5vh;
  max-height: 5vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img{
  }
}

.LogoFooter{
display: none;

}
.direction{
  display: none;
}
.directionMovil{
  display: flex;
  width: 100%;  
  min-height: 5vh;
  max-height: 5vh;
  align-items: center;
  a{
    width: 100%;
    justify-content: center;
    text-align: center;
    font-size: 0.9rem;
  }
}




.construccion{
  display: none;

  video{
   display: none;
  }
  
}


}